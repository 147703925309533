import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Redirect} from "react-router-dom";

import Session from '../../utils/Session';
import Fetch from "../../utils/Fetch";
import Constants from "../../Constants";

import styles from './LogoutLink.module.css';

LogoutLink.defaultProps = {
    text: "",
};

LogoutLink.propTypes = {
    text: PropTypes.string,
}

function LogoutLink(props) {

    const isLoggedIn = Session.isLoggedIn();
    const [requestStatus, setRequestStatus] = useState("");


    function makeLogout(){
        setRequestStatus("loading");

        let options = {
            method: 'POST',
        }
  
        let url = Constants.API_URL+"/api/logout.php";
  
        Fetch.request(url, options)
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);

                if(json.status === "ok"){
                    Session.logout();
                }

                setRequestStatus(json.status);
                
            })
            .catch((error) => {
                // console.log(error);
                setRequestStatus("error");
            });
    }

    if(!isLoggedIn){
        let to = {pathname: "/"};
        return (<Redirect to={to} />);
    }

    if(requestStatus){
        //
    }

    return (
        <div {...props} className={styles.link} onClick={makeLogout}>Logout</div>
    );
}


export default LogoutLink;
module.exports = {
    login() {
        localStorage.logged_in = '1';
    },

    logout() {
        localStorage.logged_in = '0';
    },

    isLoggedIn() {
        if(!localStorage.logged_in){
            localStorage.logged_in = '0';
        }

        return localStorage.logged_in === '0' ? false : true;
    },

}